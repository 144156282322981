import React from 'react'
import { useSelector } from 'react-redux'

import { Layout } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import Notification from 'utils/notification'

import Scrollbar from '../Scrollbar'
import BaseHeader from './Header'
import Sidebar from './Sidebar'

const { Footer, Content } = Layout

function LayoutBase({ children }) {
  const whiteLabel = useSelector(state => state.whiteLabel)

  return (
    <>
      <Notification />
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar />
        <Layout>
          <BaseHeader />

          <Scrollbar
            className="scroll-area"
            style={{ maxHeight: 'calc(100vh - 65px)' }}
          >
            <>
              <Content style={{ minHeight: 'calc(100vh - 135px)' }}>
                {children}
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                {whiteLabel.title} ©{moment().format('YYYY')}
              </Footer>
            </>
          </Scrollbar>
        </Layout>
      </Layout>
    </>
  )
}

LayoutBase.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LayoutBase
