import React from 'react'
import { useSelector } from 'react-redux'

import { Grid, Layout, Row, Col } from 'antd'
import PropTypes from 'prop-types'

import AlertHeader from './Components/AlertHeader'
import CollapseButton from './Components/CollapseButton'
import DropdownMobile from './Components/Dropdown'
import LogbookHeader from './Components/LogbookHeader'
// import QRCodeReader from './Components/QRCodeReader'
import SignOut from './Components/SignOut'
import ThemeSwitcher from './Components/ThemeSwitcher'
import UnitSelector from './Components/UnitSelector'
import UserProfile from './Components/UserProfileMenu'

const { Header } = Layout

const { useBreakpoint } = Grid

function BaseHeader({
  showCollapse = true,
  showUnitSelector = true,
  showThemeSelector = true,
  showLogbook = true,
  showAlert = true,
  showUserProfile = true,
  showLogout = true,
}) {
  const screens = useBreakpoint()

  const user = useSelector(state => state.user.profile)

  return (
    <Header
      style={{
        background: 'var(--component-background)',
        padding: screens.lg ? '0 50px' : '0px',
      }}
    >
      <Row>
        <Col xs={12} sm={12} md={13} lg={11}>
          {showCollapse && <CollapseButton />}
          {showUnitSelector &&
            (screens.sm ? <UnitSelector /> : <DropdownMobile />)}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={11}
          lg={13}
          style={{ float: 'right', textAlign: 'right' }}
        >
          {/* <QRCodeReader /> */}
          {showThemeSelector && screens.sm && <ThemeSwitcher />}
          {showLogbook && screens.sm && <LogbookHeader />}
          {showAlert && screens.sm && <AlertHeader />}
          {showUserProfile && <UserProfile user={user} />}
          {showLogout && <SignOut />}
        </Col>
      </Row>
    </Header>
  )
}

BaseHeader.defaultProps = {
  showAlert: true,
  showCollapse: true,
  showLogbook: true,
  showLogout: true,
  showThemeSelector: true,
  showUnitSelector: true,
  showUserProfile: true,
}

BaseHeader.propTypes = {
  showAlert: PropTypes.bool,
  showCollapse: PropTypes.bool,
  showLogbook: PropTypes.bool,
  showLogout: PropTypes.bool,
  showThemeSelector: PropTypes.bool,
  showUnitSelector: PropTypes.bool,
  showUserProfile: PropTypes.bool,
}

export default BaseHeader
