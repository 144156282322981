import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Row, Col, Input, Button, Alert, Form } from 'antd'
import moment from 'moment'
import adalProvider from 'pages/auth/ADFS/adalProvider'
import PropTypes from 'prop-types'
import {
  signInRequest,
  signInRequestADFS,
  cleanError,
} from 'store/modules/auth/actions'
import { changeCurrentTheme } from 'store/modules/theme/actions'

import useLocalStorage from 'hooks/useLocalStorage'

import axiosClient from 'services/api'

import packageJson from '../../../package.json'
import { Logo, LogoPlaceholder } from './styles'

const { Footer, Content } = Layout

const Signin = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const whiteLabel = useSelector(state => state.whiteLabel)
  const [loading, errorMessage, attempt] = useSelector(state => [
    state.auth.loading,
    state.auth.error,
    state.auth.attempt,
  ])

  const [adfs, setAdfs] = useLocalStorage('persist:intelup@adfs', null)

  const loginADFS = () => {
    adalProvider.login()
  }

  async function handleSubmit(values) {
    dispatch(signInRequest(values.username, values.password))
  }

  useEffect(() => {
    if (adfs && Object.keys(adfs).length) {
      adalProvider.init({
        instance: adfs.instance,
        tenant: adfs.tenant,
        clientId: adfs.clientId,
        cacheLocation: 'localStorage',
      })

      if (adalProvider.getUser()) {
        const {
          profile: { upn, oid, email, unique_name },
        } = adalProvider.getUser()

        dispatch(
          signInRequestADFS(upn || email || unique_name, oid, adfs.cli_codigo)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adfs])

  useEffect(() => {
    const values = new URLSearchParams(location.search)

    if (values.has('domain')) {
      axiosClient
        .get(`ADFS/queries/DOMAIN?domain=${values.get('domain')}`)
        .then(resp => resp.data)
        .then(resp => setAdfs(resp))
    }

    if (window.location.hash) {
      adalProvider._adal.saveTokenFromHash(
        adalProvider._adal.getRequestInfo(window.location.hash)
      )
      window.location = window.location.origin
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    dispatch(cleanError())
  }, [dispatch])

  const directLogin = useCallback(() => {
    const values = new URLSearchParams(location.search)
    const username = values.get('user')
    const password = values.get('password')
    const hash = values.get('hash')

    values.set('user', null)
    values.set('password', null)
    values.set('hash', null)

    if (
      hash === 'da39a3ee5e6b4b0d3255bfef95601890afd80709' &&
      username &&
      password
    ) {
      dispatch(signInRequest(username, password))
    }
  }, [dispatch, location.search])

  useEffect(() => {
    directLogin()
  }, [directLogin])

  useEffect(() => {
    const vars = {
      '@primary-color': whiteLabel.color,
    }

    window.less.modifyVars(vars)
    dispatch(changeCurrentTheme('light'))
  }, [whiteLabel.color, dispatch])

  useEffect(() => {
    if (errorMessage) {
      form.setFieldsValue({ password: '' })
    }
  }, [form, errorMessage])

  return (
    <Layout className="layout">
      <Content>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ height: 'calc(100vh - 70px)' }}
        >
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
              <Form form={form} onFinish={handleSubmit}>
                {whiteLabel.logo ? (
                  <Logo src={whiteLabel.logo} alt="Logo" height={72} />
                ) : (
                  <LogoPlaceholder />
                )}
                {errorMessage && (
                  <Alert
                    style={{ marginBottom: 24 }}
                    message={
                      attempt
                        ? `${attempt} ${intl.formatMessage({
                            id: errorMessage,
                          })}`
                        : intl.formatMessage({ id: errorMessage })
                    }
                    description={
                      errorMessage === 'global.error.messages.serverError'
                        ? window._env_.ENV_API_URL
                        : null
                    }
                    type="error"
                    showIcon
                    closable
                  />
                )}

                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'auth.signin.typeUser',
                      }),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    disabled={loading}
                    autocorrect="off"
                    autocapitalize="off"
                    prefix={
                      <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder={intl.formatMessage({
                      id: 'global.labels.user',
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'auth.signin.typePassword',
                      }),
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    disabled={loading}
                    autocorrect="off"
                    autocapitalize="off"
                    prefix={
                      <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder={intl.formatMessage({
                      id: 'global.labels.password',
                    })}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="login-form-button"
                    loading={loading}
                  >
                    {intl.formatMessage({ id: 'global.button.enter' })}
                  </Button>
                </Form.Item>
                {!!adfs && Object.keys(adfs).length > 0 && (
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      size="large"
                      className="login-form-button"
                      onClick={loginADFS}
                    >
                      ADFS
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        © {moment().format('YYYY')} - v{packageJson.version}
      </Footer>
    </Layout>
  )
}

Signin.defaultProps = {
  user: null,
}

Signin.propTypes = {
  user: PropTypes.shape({}),
}

export default Signin
