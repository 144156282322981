import { REHYDRATE } from 'redux-persist/lib/constants'
import {
  takeLatest,
  takeLeading,
  call,
  put,
  all,
  fork,
  select,
  delay,
} from 'redux-saga/effects'

import api from 'services/api'

import {
  setAllTags,
  setAllTagsFailure,
  setUnitTags,
  setSpcTags,
  setAllSpcTags,
  getAllTags,
} from './actions'
import { getUnitSelected } from './selectors'

export function* checkTick() {
  const minutes = 15
  const minutesToMs = m => m * 60 * 1000

  while (true) {
    yield delay(minutesToMs(minutes))
    yield put(getAllTags())
  }
}

function* checkTimer() {
  // starts the task in the background
  yield fork(checkTick)
}

export function* getTags() {
  try {
    const unitSelected = yield select(getUnitSelected)

    if (api.defaults.headers.common.Authorization) {
      const response = yield call(api.get, 'MES/entities/MESTAG/CLIENT')
      const { data: spcTags } = yield call(api.get, 'MES/entities/SPCTAGS')

      const { data } = response.data
      const unitTags = data.filter(tag => tag.plt_codigo === unitSelected)

      if (data) {
        yield put(setAllTags(data))
        yield put(setUnitTags(unitTags))
        yield put(
          setSpcTags(
            spcTags
              .filter(spc => unitTags.find(tag => tag.id_tag === spc.id_tag))
              .filter(
                (item, index, self) =>
                  index === self.findIndex(t => t.id_tag === item.id_tag)
              )
          )
        )
        yield put(
          setAllSpcTags(
            spcTags.filter(
              (item, index, self) =>
                index === self.findIndex(t => t.id_tag === item.id_tag)
            )
          )
        )
      }
    }
  } catch (err) {
    yield put(
      setAllTagsFailure(
        err.message ||
          'Houve um problema com a api, por favor, entre em contato conosco e ajude-nos a resolver.'
      )
    )
  }
}

export default all([
  takeLatest('@tags/GET_ALL', getTags),
  takeLeading(REHYDRATE, checkTimer),
])
