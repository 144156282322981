import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Icon from '@ant-design/icons'
import { faBuilding } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Dropdown, Tabs } from 'antd'
import { checkContractRequest } from 'store/modules/contract/actions'
import { setUnitSelected } from 'store/modules/unitSelected/actions'

const { TabPane } = Tabs

function DropdownMobile() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const unitSelected = useSelector(state => state.unitSelected.data)
  const allUnits = useSelector(state => state.units.data)

  function formatUnitLabel(unit) {
    if (unit) {
      return `${unit.plt_codigo} - ${unit.ele_descri}`
    }

    return null
  }

  function getUnit(pltCode) {
    return allUnits.find(u => u.plt_codigo === pltCode)
  }

  async function handleChangeUnit(option) {
    dispatch(setUnitSelected(getUnit(option.key)))
    dispatch(checkContractRequest())

    history.push('/')
  }

  const menuUnit = (
    <Menu onClick={handleChangeUnit}>
      {allUnits.map(item => (
        <Menu.Item
          key={item.plt_codigo}
          style={{
            background: unitSelected === item.plt_codigo ? '#e6f7ff' : '#fff',
            fontWeight: unitSelected === item.plt_codigo ? 'bold' : 'normal',
          }}
        >
          {formatUnitLabel(item)}
        </Menu.Item>
      ))}
    </Menu>
  )

  const tabs = (
    <Menu>
      <Tabs
        size="small"
        tabBarStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <TabPane key="1">{menuUnit}</TabPane>
      </Tabs>
    </Menu>
  )

  return (
    <Dropdown
      visible={dropdownOpen}
      onVisibleChange={setDropdownOpen}
      overlay={tabs}
      trigger={['click']}
      placement="bottom"
    >
      <Icon
        data-testid="dropdown-units"
        style={{
          fontSize: 18,
          lineHeight: '64px',
          alignSelf: 'center',
          cursor: 'pointer',
          padding: '0 15px',
        }}
        component={() => <FontAwesomeIcon icon={faBuilding} />}
      />
    </Dropdown>
  )
}

export default DropdownMobile
