import React, { lazy, Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Router, Switch } from 'react-router-dom'

import { RouteProvider } from 'contexts/routeContext'
import Signin from 'pages/auth/Signin'
import ExpiredScreenPage from 'pages/ExpiredScreen'
import darkVars from 'styles/dark.json'
import lightVars from 'styles/light.json'

import Layout from 'components/Layout'

import history from 'services/history'

import Route from './Route'

const Main = lazy(() => import(/* webpackPrefetch: true */ 'pages/Main'))
const NotFoundPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/notFound')
)
const DashboardInitialPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dashboard/initialPage')
)
const DashboardPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dashboard')
)

const AnalisysProcessPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/analytics/process')
)
const AnalisysCorrelationPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/analytics/correlation')
)
const AnalisysComparativePage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/analytics/comparativeBetweenPeriods'
  )
)
const AnalisysEventManagerPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/analytics/eventManager')
)
const AnalisysOperatorPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/analytics/operator')
)
const AnalisysReportPeriodsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/analytics/reportPerPeriods')
)
const DataMiningClusteringPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataMining/clustering')
)
const DataMiningRegressionPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataMining/regression')
)
const ControlLoopManagementOverviewPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/controlLoopManagement/Overview')
)
const ControlLoopManagementPerformancePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/controlLoopManagement/Performance')
)
const StatisticalProcessControlExploratoryPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/statisticalProcessControl/exploratory'
  )
)
const StatisticalProcessControlManagementPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/statisticalProcessControl/management'
  )
)
const StatisticalProcessControlProductionPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/statisticalProcessControl/production'
  )
)
const RootCauseEffectsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/rootCause/Effects')
)

const RootCauseGenerateAnalysisPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/rootCause/GenerateAnalysis')
)
const RootCauseActionList = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/rootCause/ActionList')
)
const EquipamentManagementStoppingChartPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/equipmentManagement/stoppingChart')
)
const EquipamentManagementStoppingPointPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/equipmentManagement/stoppingPoint')
)
const EquipamentManagementApproveStopPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/equipmentManagement/approveStop')
)
const AlarmManagementPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/alarmManagement')
)
const ProductionOrderPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/productionOrder')
)
const ManufacturePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/manufacture')
)
const ManufactureJunkReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/manufacture/reports/junk')
)
const ManufactureApprovals = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/manufacture/approvals')
)
const Impairments = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/operationalSecurity/Impairments')
)
const RoutineTests = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/operationalSecurity/RoutineTests')
)
const ManagementOfChanges = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/managementOfChanges')
)
const ManufactureStagePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/manufacture/components/StageList')
)
const DataEntryCsvPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataEntry/csv')
)
const DataEntryManualCollectionPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataEntry/manualCollection')
)
const DataEntryManualInputPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataEntry/manualInput')
)
const DataEntryLogbookPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/dataEntry/logbook')
)

const DataExportPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/export/dataExport')
)
const EventExportPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/export/eventExport')
)
const OperationalAlertPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/alert')
)
const ConsistencyEquipamentStopPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/integratingConsistency/equipmentStop'
  )
)
const ConsistencyProductionProcessStopPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/integratingConsistency/productionProcess'
  )
)
const LogPage = lazy(() => import(/* webpackPrefetch: true */ 'pages/Log'))
const ScalePage = lazy(() => import(/* webpackPrefetch: true */ 'pages/scale'))
const IntegratedMaintenanceOsGenarator = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/integratedMaintenance/OsGenerator')
)
const IntegratedMaintenanceServiceOrder = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/integratedMaintenance/ServiceOrder')
)
const IntegratedMaintenanceProgramming = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/integratedMaintenance/Programming')
)
const IntegratedMaintenanceBenchAppointment = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/integratedMaintenance/BenchAppointment'
  )
)
const IntegratedMaintenanceServiceOrderPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/integratedMaintenance/ServiceOrder/OperationalPage'
  )
)
const IntegratedMaintenanceReportPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/integratedMaintenance/Reports')
)
const IntegratedMaintenanceTelemetryPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/integratedMaintenance/Telemetry')
)

const SettingsUserRegistrationPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/user')
)
const SettingsAccessProfilePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/accessProfile')
)
const SettingsTagsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/tag')
)
const SettingsParametersClientPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/paremetersClient/ParametersClient'
  )
)
const SettingsDateHourPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/dateHour'
  )
)
const SettingsPdfTemplatePage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/pdfTemplate'
  )
)
const SettingsTagsGroupPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/tagsGroup')
)
const SettingsGoalsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/goals')
)
const SettingsAlertGroupPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/alertGroup')
)
const SettingsAlertConfigurationPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/alertConfiguration')
)
const SettingsEventManagerFramePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/eventManager')
)
const SettingsEventManagerMotivesGroupPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/motivesGroup')
)
const SettingsEventManagerComplementaryTypesPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/complementaryTypes')
)
const SettingsControlLoopManagementControlPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/controlLoopManagementConfig'
  )
)
const SettingsUnitMeasurementPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/unitMeasurement'
  )
)
const SettingsBarcodeLabelPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/barcodeLabel'
  )
)
const SettingsProduct = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/product'
  )
)
const SettingsTokenPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/token'
  )
)
const SettingsGlobalVariablesPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/globalVariables')
)
const SettingsOeePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/oee/OEE')
)
const SettingsRootCausePage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/rootCause/Causes')
)

const SettingsRootCauseRulesPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/rootCause/RootCauseRules'
  )
)

const SettingsLogbookGroupPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/logbookGroup')
)
const SettingsIntegrationApiPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/integration/API')
)
const SettingsIntegrationApiFormPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/integration/API/form')
)
const SettingsIntegrationLaboratoryPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integration/Laboratory'
  )
)
const SettingsIntegrationSystemPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/integration/system')
)
const SettingsIntegrationParametersPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integration/Parameters'
  )
)
const SettingsStatisticalProcessControlCardsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/cards')
)
const SettingsStatisticalProcessControlRulesPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/statisticalProcessControl'
  )
)
const SettingsStatisticalProcessControlHelpListPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/helpList')
)
const SettingsStatisticalProcessControlDiagnosticsListPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/diagnosticsList')
)
const SettingsStatisticalProcessControlCorrectiveActionsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/correctiveActions')
)
const SettingsManualGroupCollectPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/manualCollectGroup')
)
const SettingsManualCollectTagsPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/manualCollect')
)
const SettingsElementsTree = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalStructure/elementsTree'
  )
)
const SettingsIntegratedMaintenanceReportRegistration = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/ReportConfiguration'
  )
)
const SettingsIntegratedMaintenanceMaterialsServices = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/MaterialsServices'
  )
)
const SettingsIntegratedMaintenanceModalities = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/Modalities'
  )
)
const SettingsIntegratedMaintenanceFrequency = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/Frequency'
  )
)
const SettingsIntegratedMaintenanceStatus = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/Status'
  )
)
const SettingsIntegratedMaintenanceMaintenanceActivity = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/MaintenanceActivity'
  )
)
const SettingsIntegratedMaintenancePriority = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/Priority'
  )
)
const SettingsIntegratedMaintenanceServiceOrderTypes = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/ServiceOrderTypes'
  )
)
const SettingsIntegratedMaintenanceLaborRules = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/LaborRules'
  )
)
const SettingsIntegratedMaintenancePreventiveServices = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/PreventiveServices'
  )
)
const SettingsIntegratedMaintenanceInspectionRoutes = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/integratedMaintenance/InspectionRoutes'
  )
)
const SettingsManufactureStatus = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/manufacture/Status')
)
const SettingsManufactureDefect = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/manufacture/Defect')
)
const SettingsManufactureChecklist = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/Checklist'
  )
)
const SettingsManufactureChecklistItems = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/ChecklistItems'
  )
)
const SettingsManufactureVariation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/Variations'
  )
)
const SettingsManufactureSrcOrder = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/manufacture/SrcOrder')
)

const SettingsManufactureChecklistMatrix = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/CheckListApprovalMatrix'
  )
)

const SettingsManufacturecomplementaryTable = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/ComplementaryTable'
  )
)

const SettingsManufactureMotiveGroup = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/MotiveGroup'
  )
)

const SettingsManufactureInspectionLevel = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/InspectionLevel'
  )
)

const SettingsManufactureAcceptableQualityLevel = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/manufacture/AcceptableQualityLevel'
  )
)

const SettingsOperationalSecurityInterlock = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentInterlock'
  )
)

const SettingsOperationalSecurityRisk = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentRisk'
  )
)

const SettingsOperationalSecuritySeverity = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentSeverity'
  )
)
const SettingsOperationalSecurityFrequency = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentFrequency'
  )
)

const SettingsOperationalSecurityRule = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/ImpairmentRule'
  )
)
const SettingsOperationalSecurityMitigationMeasures = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentMitigationMeasures'
  )
)

const SettingsOperationalSecurityPrefix = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/registration/InstrumentPrefix'
  )
)

const SettingsOperationalSecurityInstrument = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/Instrument'
  )
)

const SettingsOperationalSecurityUserGroupTree = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/UserGroupsTree'
  )
)

const SettingsOperationalSecurityApprovalMatrix = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/operationalSecurity/ApprovalMatrix'
  )
)

const SettingsManagementOfChangeAreaCriticality = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/managementOfChanges/AreaCriticality'
  )
)

const SettingsManagementOfRisk = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/managementOfChanges/Risk'
  )
)

const SettingsManagementOfChangeResponsibleSector = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/managementOfChanges/ResponsibleSector'
  )
)

const SettingsManagementOfApprovalMatrix = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'pages/configuration/managementOfChanges/ApprovalMatrix'
  )
)

const SettingsUserGroup = lazy(() =>
  import(/* webpackPrefetch: true */ 'pages/configuration/userGroup')
)

const Settings = () => (
  <Switch>
    <Route
      path="/settings/access-control/user-registration"
      component={SettingsUserRegistrationPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/access-control/user-group"
      component={SettingsUserGroup}
      exact
      isPrivate
    />
    <Route
      path="/settings/access-control/access-profile"
      component={SettingsAccessProfilePage}
      exact
      isPrivate
    />
    <Route path="/settings/tags" component={SettingsTagsPage} exact isPrivate />
    <Route
      path="/settings/operational-structure/parameters"
      component={SettingsParametersClientPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-structure/elements-tree"
      component={SettingsElementsTree}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-structure/shifts-and-times"
      component={SettingsDateHourPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-structure/pdf-template"
      component={SettingsPdfTemplatePage}
      exact
      isPrivate
    />
    <Route
      path="/settings/tags/groups"
      component={SettingsTagsGroupPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/tags/goals"
      component={SettingsGoalsPage}
      exact
      isPrivate
    />

    <Route
      path="/settings/alerts/alerts-group"
      component={SettingsAlertGroupPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/alerts/alerts-configuration"
      component={SettingsAlertConfigurationPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/event-manager/frames"
      component={SettingsEventManagerFramePage}
      exact
      isPrivate
    />
    <Route
      path="/settings/event-manager/motives-group"
      component={SettingsEventManagerMotivesGroupPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/event-manager/complementary-types"
      component={SettingsEventManagerComplementaryTypesPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/control-loop-management"
      component={SettingsControlLoopManagementControlPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/token"
      component={SettingsTokenPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/unit-measurement"
      component={SettingsUnitMeasurementPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/barcode-label"
      component={SettingsBarcodeLabelPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/product"
      component={SettingsProduct}
      exact
      isPrivate
    />
    <Route
      path="/settings/global-variables"
      component={SettingsGlobalVariablesPage}
      exact
      isPrivate
    />
    <Route path="/settings/oee" component={SettingsOeePage} exact isPrivate />
    <Route
      path="/settings/root-cause/causes"
      component={SettingsRootCausePage}
      exact
      isPrivate
    />
    <Route
      path="/settings/root-cause/rules"
      component={SettingsRootCauseRulesPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/logbook-group"
      component={SettingsLogbookGroupPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/integration/api"
      component={SettingsIntegrationApiPage}
      exact
      isPrivate
    />

    <Route
      path="/settings/integration/laboratory"
      component={SettingsIntegrationLaboratoryPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/integration/system"
      component={SettingsIntegrationSystemPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/integration/integration-parameters"
      component={SettingsIntegrationParametersPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/statistical-control/cards"
      component={SettingsStatisticalProcessControlCardsPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/statistical-control/rules"
      component={SettingsStatisticalProcessControlRulesPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/statistical-control/help"
      component={SettingsStatisticalProcessControlHelpListPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/statistical-control/diagnostic-list"
      component={SettingsStatisticalProcessControlDiagnosticsListPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/statistical-control/corrective-actions"
      component={SettingsStatisticalProcessControlCorrectiveActionsPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/manual-collector/group-collect"
      component={SettingsManualGroupCollectPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/manual-collector/tags-collect"
      component={SettingsManualCollectTagsPage}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/report-registration"
      component={SettingsIntegratedMaintenanceReportRegistration}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/materials-services"
      component={SettingsIntegratedMaintenanceMaterialsServices}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/modalities"
      component={SettingsIntegratedMaintenanceModalities}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/frequency"
      component={SettingsIntegratedMaintenanceFrequency}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/status"
      component={SettingsIntegratedMaintenanceStatus}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/maintenance-activity"
      component={SettingsIntegratedMaintenanceMaintenanceActivity}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/priority"
      component={SettingsIntegratedMaintenancePriority}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/service-order-types"
      component={SettingsIntegratedMaintenanceServiceOrderTypes}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/labor-rules"
      component={SettingsIntegratedMaintenanceLaborRules}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/services"
      component={SettingsIntegratedMaintenancePreventiveServices}
      exact
      isPrivate
    />
    <Route
      path="/settings/integrated-maintenance/inspection-routes"
      component={SettingsIntegratedMaintenanceInspectionRoutes}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/defect"
      component={SettingsManufactureDefect}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/status"
      component={SettingsManufactureStatus}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/checklist"
      component={SettingsManufactureChecklist}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/checklist-items"
      component={SettingsManufactureChecklistItems}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/variation"
      component={SettingsManufactureVariation}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/src-order"
      component={SettingsManufactureSrcOrder}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/checklist-matrix"
      component={SettingsManufactureChecklistMatrix}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/complementary-table"
      component={SettingsManufacturecomplementaryTable}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/motive-group"
      component={SettingsManufactureMotiveGroup}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/inspection-level"
      component={SettingsManufactureInspectionLevel}
      exact
      isPrivate
    />
    <Route
      path="/settings/manufacture/acceptable-quality-level"
      component={SettingsManufactureAcceptableQualityLevel}
      exact
      isPrivate
    />
    <Route
      path="/settings/operationalSecurity/registration/instrument-interlock"
      component={SettingsOperationalSecurityInterlock}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/registration/instrument-risk"
      component={SettingsOperationalSecurityRisk}
      exact
      isPrivate
    />
    <Route
      path="/settings/operationalSecurity/registration/instrument-severity"
      component={SettingsOperationalSecuritySeverity}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/registration/instrument-frequency"
      component={SettingsOperationalSecurityFrequency}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/registration/instrument-mitigation-measures"
      component={SettingsOperationalSecurityMitigationMeasures}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/registration/instrument-prefix"
      component={SettingsOperationalSecurityPrefix}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/impairment-rule"
      component={SettingsOperationalSecurityRule}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/instrument"
      component={SettingsOperationalSecurityInstrument}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/user-groups-tree"
      component={SettingsOperationalSecurityUserGroupTree}
      exact
      isPrivate
    />
    <Route
      path="/settings/operational-security/approval-matrix"
      component={SettingsOperationalSecurityApprovalMatrix}
      exact
      isPrivate
    />
    <Route
      path="/settings/management-of-change/area-criticality"
      component={SettingsManagementOfChangeAreaCriticality}
      exact
      isPrivate
    />
    <Route
      path="/settings/management-of-change/risk"
      component={SettingsManagementOfRisk}
      exact
      isPrivate
    />
    <Route
      path="/settings/management-of-change/responsible-sector"
      component={SettingsManagementOfChangeResponsibleSector}
      exact
      isPrivate
    />
    <Route
      path="/settings/management-of-changes/approval-matrix"
      component={SettingsManagementOfApprovalMatrix}
      exact
      isPrivate
    />
  </Switch>
)

const RestrictArea = () => (
  <Layout>
    <Suspense
      fallback={
        <div>
          <FormattedMessage id="global.loading.messages.loading" />
        </div>
      }
    >
      <Switch>
        <Route
          path="/settings/integration/api/:id"
          component={SettingsIntegrationApiFormPage}
          isPrivate
        />
        <Route
          path="/dashboard"
          component={DashboardInitialPage}
          exact
          isPrivate
        />
        <Route
          path="/dashboard/:id"
          component={DashboardPage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/analysis-process"
          component={AnalisysProcessPage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/correlation"
          component={AnalisysCorrelationPage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/comparative-between-periods"
          component={AnalisysComparativePage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/event-manager"
          component={AnalisysEventManagerPage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/operator"
          component={AnalisysOperatorPage}
          exact
          isPrivate
        />
        <Route
          path="/analysis/report-per-periods"
          component={AnalisysReportPeriodsPage}
          exact
          isPrivate
        />
        <Route
          path="/data-mining/regression"
          component={DataMiningRegressionPage}
          exact
          isPrivate
        />
        <Route
          path="/data-mining/clustering"
          component={DataMiningClusteringPage}
          exact
          isPrivate
        />
        <Route
          path="/control-loop-management/overview"
          component={ControlLoopManagementOverviewPage}
          exact
          isPrivate
        />
        <Route
          path="/control-loop-management/performance"
          component={ControlLoopManagementPerformancePage}
          exact
          isPrivate
        />
        <Route
          path="/statistical-control/exploratory"
          component={StatisticalProcessControlExploratoryPage}
          exact
          isPrivate
        />
        <Route
          path="/statistical-control/management"
          component={StatisticalProcessControlManagementPage}
          exact
          isPrivate
        />
        <Route
          path="/statistical-control/production"
          component={StatisticalProcessControlProductionPage}
          exact
          isPrivate
        />

        <Route
          path="/root-cause/effects-page"
          component={RootCauseEffectsPage}
          isPrivate
        />
        <Route
          path="/root-cause/generate-analysis"
          component={RootCauseGenerateAnalysisPage}
          exact
          isPrivate
        />
        <Route
          path="/root-cause/action-list"
          component={RootCauseActionList}
          exact
          isPrivate
        />
        <Route
          path="/equipment-management/stopping-chart"
          component={EquipamentManagementStoppingChartPage}
          exact
          isPrivate
        />
        <Route
          path="/equipment-management/stopping-point"
          component={EquipamentManagementStoppingPointPage}
          exact
          isPrivate
        />
        <Route
          path="/equipment-management/approve-stop"
          component={EquipamentManagementApproveStopPage}
          exact
          isPrivate
        />
        <Route
          path="/alarm-management"
          component={AlarmManagementPage}
          exact
          isPrivate
        />
        <Route
          path="/ops-management"
          component={ProductionOrderPage}
          exact
          isPrivate
        />
        <Route
          path="/manufacture/panel"
          component={ManufacturePage}
          exact
          isPrivate
        />
        <Route
          path="/manufacture/junk-report"
          component={ManufactureJunkReport}
          exact
          isPrivate
        />
        <Route
          path="/manufacture/approvals"
          component={ManufactureApprovals}
          exact
          isPrivate
        />
        <Route
          path="/manufacture/panel/:id"
          component={ManufactureStagePage}
          isPrivate
        />
        <Route
          path="/operational-security/impairment"
          component={Impairments}
          isPrivate
        />
        <Route
          path="/operational-security/routine-tests"
          component={RoutineTests}
          isPrivate
        />
        <Route
          path="/management-of-changes"
          component={ManagementOfChanges}
          isPrivate
        />
        <Route
          path="/data-entry/import-csv"
          component={DataEntryCsvPage}
          exact
          isPrivate
        />
        <Route
          path="/data-entry/manual-collector"
          component={DataEntryManualCollectionPage}
          exact
          isPrivate
        />
        <Route
          path="/data-entry/manual-input"
          component={DataEntryManualInputPage}
          exact
          isPrivate
        />
        <Route
          path="/data-entry/logbook"
          component={DataEntryLogbookPage}
          exact
          isPrivate
        />
        <Route path="/export/data" component={DataExportPage} exact isPrivate />
        <Route
          path="/export/event"
          component={EventExportPage}
          exact
          isPrivate
        />
        <Route
          path="/operational-alerts"
          component={OperationalAlertPage}
          exact
          isPrivate
        />
        <Route
          path="/consistency/production-process"
          component={ConsistencyProductionProcessStopPage}
          exact
          isPrivate
        />
        <Route
          path="/consistency/equipment-stopping"
          component={ConsistencyEquipamentStopPage}
          exact
          isPrivate
        />
        <Route path="/log" component={LogPage} exact isPrivate />
        <Route path="/scale" component={ScalePage} exact isPrivate />
        <Route
          path="/integrated-maintenance/service-order-panel"
          component={IntegratedMaintenanceServiceOrder}
          exact
          isPrivate
        />
        <Route
          path="/integrated-maintenance/service-order-programming"
          component={IntegratedMaintenanceProgramming}
          exact
          isPrivate
        />
        <Route
          path="/integrated-maintenance/service-order-panel/:id"
          component={IntegratedMaintenanceServiceOrderPage}
          isPrivate
        />
        <Route
          path="/integrated-maintenance/report"
          component={IntegratedMaintenanceReportPage}
          isPrivate
        />
        <Route
          path="/integrated-maintenance/telemetry"
          component={IntegratedMaintenanceTelemetryPage}
          isPrivate
        />
        <Route
          path="/integrated-maintenance/service-order-generator"
          component={IntegratedMaintenanceOsGenarator}
          exact
          isPrivate
        />
        <Route
          path="/integrated-maintenance/service-order-labor-appointment"
          component={IntegratedMaintenanceBenchAppointment}
          exact
          isPrivate
        />
        {/* <Route
          path="/integrated-maintenance/maintenance-overview/history"
          component={IntegratedMaintenanceMaintenanceOverviewHistory}
          exact
          isPrivate
        />
        <Route
          path="/integrated-maintenance/maintenance-overview/graphs"
          component={IntegratedMaintenanceMaintenanceOverviewGraphs}
          exact
          isPrivate
        /> */}

        <Route path="/main" component={Main} exact isPrivate />
        <Route path="/settings" component={Settings} isPrivate />
        <Route path="/" component={NotFoundPage} isPrivate />
      </Switch>
    </Suspense>
  </Layout>
)

export default function Routes() {
  const whiteLabel = useSelector(state => state.whiteLabel)
  const theme = useSelector(state => state.theme.currentTheme)

  let vars = theme === 'dark' ? darkVars : lightVars

  vars = {
    ...vars,
    '@white': '#fff',
    '@black': '#000',
    '@primary-color': whiteLabel.color || '@blue-6',
  }

  window.less.modifyVars(vars)

  return (
    <Router history={history}>
      <RouteProvider>
        <Switch>
          <Route path="/" component={Signin} exact />
          <Route path="/login" component={Signin} exact />
          <Route
            path="/expired"
            component={ExpiredScreenPage}
            isPrivate
            exact
          />

          <RestrictArea />
        </Switch>
      </RouteProvider>
    </Router>
  )
}
