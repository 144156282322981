/* eslint-disable no-console */
import axios from 'axios'

const baseURL = window._env_?.ENV_API_URL || ''

const api = axios.create({
  baseURL: `${baseURL}/intelup/`,
  validateStatus(status) {
    return status >= 200 && status < 500 // default
  },
})

api.postOrPut = (url, id, data, config = {}) => {
  const method = id ? 'put' : 'post'
  const apiUrl = id ? `${url}/${id}` : url

  return api[method](apiUrl, data, config)
}

api.interceptors.response.use(undefined, error => {
  // whatever you want to do with the error
  console.table({
    code: error.response?.status,
    url: `${error.response?.config.baseURL}${error.response?.config.url}`,
    description: error.response?.data?.description,
    ...error.response?.data.traceback,
    ...error.response?.config.headers,
  })

  throw error
})

export default api

export function axiosInstance() {
  const instance = axios.create({
    baseURL: `${baseURL}/intelup/`,
    validateStatus(status) {
      return status >= 200 && status < 500 // default
    },
  })

  instance.postOrPut = (url, id, data, config = {}) => {
    const method = id ? 'put' : 'post'
    const apiUrl = id ? `${url}/${id}` : url

    return instance[method](apiUrl, data, config)
  }

  instance.interceptors.response.use(undefined, error => {
    // whatever you want to do with the error
    console.table({
      code: error.response?.status,
      url: `${error.response?.config.baseURL}${error.response?.config.url}`,
      description: error.response?.data?.description,
      ...error.response?.data.traceback,
      ...error.response?.config.headers,
    })

    throw error
  })
  instance.defaults.headers.common = api.defaults.headers.common
  return instance
}
