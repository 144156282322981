import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import esES from 'antd/es/locale/es_ES'
import ptBR from 'antd/es/locale/pt_BR'
import PropTypes from 'prop-types'

const availableMessages = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
  es: esES,
  pt: ptBR,
  en: enUS,
}

const getMessages = localeKey => {
  const messages = availableMessages[localeKey]

  return messages
}

export function AntdProvider({ children }) {
  const localeKey = useSelector(state => state.locale.key)
  const locale = useMemo(() => getMessages(localeKey), [localeKey])

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>
}

AntdProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default AntdProvider
