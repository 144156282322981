import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import createCompressor from 'redux-persist-transform-compress'
import storage from 'redux-persist/lib/storage'

import accessControl from './accessControl/reducers'
import auth from './auth/reducers'
import contract from './contract/reducers'
import dateConfig from './dateConfig/reducers'
import frames from './frames/reducers'
import locale from './locale/reducers'
import siderMenu from './siderMenu/reducers'
import tags from './tags/reducers'
import theme from './theme/reducers'
import units from './units/reducers'
import unitSelected from './unitSelected/reducers'
import user from './user/reducers'
import whiteLabel from './whiteLabel/reducers'

const compressor = createCompressor({})

const themePersistConfig = {
  key: 'intelup@theme',
  storage,
}

const tagsPersistConfig = {
  key: 'intelup@tags',
  storage,
  transforms: [compressor],
}

const framesPersistConfig = {
  key: 'intelup@frames',
  storage,
  transforms: [compressor],
}

const unitsPersistConfig = {
  key: 'intelup@units',
  storage,
}

export default combineReducers({
  locale,
  auth,
  user,
  units: persistReducer(unitsPersistConfig, units),
  unitSelected,
  accessControl,
  siderMenu,
  contract,
  whiteLabel,
  theme: persistReducer(themePersistConfig, theme),
  tags: persistReducer(tagsPersistConfig, tags),
  frames: persistReducer(framesPersistConfig, frames),
  dateConfig,
})
